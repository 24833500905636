// React Utils
import React from "react";

// ActernityUI Laptop Screen Scroll
import { ContainerScroll } from "../../components/ui/container-scroll-animation";

// Icons
import { FaBolt } from "react-icons/fa6";

// Images
import CardImage from "../../assets/images/NewLandingPage/CardImage.png";
// import CaseMinisterVideo from "../../assets/images/NewLandingPage/CaseMinisterVideo.mp4";
// import CaseMinisterVideoMobile from "../../assets/images/NewLandingPage/CaseMinisterVideoMobile.mp4";
import MainVideoThumbnail from "../../assets/images/NewLandingPage/MainVideo.png";

export default function HeroSection() {
  return (
    <div className="flex flex-col overflow-hidden bg-[url('/src/assets/images/NewLandingPage/HeroBackground.png')] bg-[70%_0px] max-sm:bg-[50%_0px] dark:bg-none">
      <ContainerScroll
        titleComponent={
          <div className="flex flex-col gap-3 items-center max-sm:pt-20 max-sm:gap-5">
            <h1 className="text-[3.8rem] max-sm:text-[26px] max-sm:leading-[42px] leading-[83px] font-bold font-sora text-black dark:text-white">
              Revolutionize Legal Work with{" "}
              <span className="">
                <span className="inline-flex gap-1 items-center text-transparent bg-clip-text bg-gradient-to-b from-[#521EDF] to-[#D028CB]">
                  AI
                  <FaBolt className="inline text-[3rem] max-sm:text-[1.5rem]  text-[#c527cc]" />
                </span>
              </span>{" "}
              Powered Case Management
            </h1>
            <p className="max-w-4xl max-sm:px-2 max-sm:text-sm max-sm:leading-[21px] mx-auto text-center text-xl font-poppins leading-[32px] mb-2">
              Empowering Legal Professionals with AI-Powered Tools. Get instant
              legal insights, track your cases in real-time, and streamline your
              workflow with our easy-to-use platform.
            </p>
            {!localStorage.getItem("isAuthenticated") && (
              <a
                href="/signup"
                target="_blank"
                className="w-[172px] block rounded-[12px] max-sm:scale-75 max-sm:-mb-8 mx-auto py-3 mb-4 dark:bg-white dark:text-black text-white font-poppins font-semibold bg-[#131606]"
              >
                Start Free Trial
              </a>
            )}
          </div>
        }
      >
        <div className="h-full w-full flex items-center bg-black max-sm:h-full max-sm:items-center max-sm:hidden">
          <video
            width="100%"
            height="100%"
            controls
            autoPlay
            muted
            poster="https://caseminister.blob.core.windows.net/frontend/MainVideo.png"
          >
            <source
              // src={CaseMinisterVideo}
              src="https://caseminister.blob.core.windows.net/video/CaseMinisterVideo.mp4"
              type="video/mp4"
              className="max-sm:hidden"
            />
            <img
              src={CardImage}
              alt="case-management"
              height={"100%"}
              width={1400}
              className="mx-auto rounded-2xl object-contain h-full object-left-top"
              draggable={false}
            />
          </video>
        </div>
        <div className="h-full w-full max-sm:items-center hidden max-sm:flex">
          <video
            controls
            autoPlay
            muted
            poster="https://caseminister.blob.core.windows.net/frontend/MainVideo.png"
            className="w-fit h-full mx-auto"
          >
            <source
              // src={CaseMinisterVideoMobile}
              src="https://caseminister.blob.core.windows.net/video/CaseMinisterVideoMobile.mp4"
              type="video/mp4"
              className="max-sm:hidden"
            />
            <img
              src={CardImage}
              alt="case-management"
              height={"100%"}
              width={1400}
              className="mx-auto rounded-2xl h-full object-left-top"
              draggable={false}
            />
          </video>
        </div>
      </ContainerScroll>
    </div>
  );
}
